
.container-usuarios {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.usuario-top{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgb(43, 31, 116);
    width: 50%;
    margin: 5px auto;
    height: 60px;
    color: #fff;
    border-radius: 10px;
}

.usuario-top h3 {
    color: #fff;
}

.container-top-usuario {
    display: flex;    
    font-size: 16px;
    margin: 5px auto;
    width: 50%;
    height: 60px;
    min-height: 50px;
}

.column-top-usuario {
    flex: 1;
    text-align: center;
    padding: 10px;
    margin: 5px;
    width: 80%;
    border: none;
    border-radius: 10px;
    background-color: rgb(20, 62, 87);
}

.column-top-usuario-btn {
    background-color: rgb(20, 62, 87);
    /* background-color: rgb(46, 99, 129); */
    padding: 10px;
    margin: 5px;
    width: 20%;
    border-radius: 10px;
}

.column-top-usuario-btn button {
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #fff;
    background-color: rgb(20, 62, 87);
}


.servico-meio-usuario{
    background-color: #fff;
    display: flex;
    justify-content: center;        
    margin: 10px auto;
    width: 100%;
    border-radius: 10px;
    min-height: 500px;
}

.table-body-usuario {
    font-size: 1em;
}

.btnNovo-usuario button {
    border: none;
    border-radius: 10px;
    background-color: rgb(43, 31, 116);
    color: #fff;
    cursor: pointer;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
}
