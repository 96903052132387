.btn-fat-gerar {
    width: 80px;
    color: #fff;
    font-size: 14px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background-color: rgb(56, 143, 114);
}

.btn-fat-fechar {
    width: 80px;
    height: 40px;
    font-size: 14px;
    border: none;
    color: #fff;
    border-radius: 10px;
    background-color: #010101
}

.geracao-custom-textfield {
    width: 200px;
    background-color: #fff;   
}
