
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Times New Roman', Times, serif;
  color: #0f1729; 
  overflow-x: hidden;
  overflow-y: hidden;
}

body{  
  /* background: url('./img/fundo2.jpg'); */
  background-color: rgba(181,194,202,255);
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}
