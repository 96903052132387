
.container {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-login {
    /* width: 100%; */
    min-height: 100vh;
    /* display: flex; */
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    /* padding: 15px; */
    margin-top: -60px;
    /* background-color: #111; */
  }
  
  .wrap-loginxxxxx {
    width: 390px;
    background-color: #333;
    border-radius: 10px;
    overflow: hidden;
    /* padding: 77px 55px 33px 55px; */
    /* box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2); */
  }