.container-nfse {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.container-top-nfse {
    display: flex;
    font-size: 16px;
    margin: 5px auto;
    width: 50%;
    height: 60px;
    min-height: 50px;
}

.column-nfse-top {
    flex: 1;
    text-align: center;
    padding: 10px;
    margin: 5px;
    width: 80%;
    border: none;
    border-radius: 10px;
    /* background-color: #76848c; */
    background-color: rgb(20, 62, 87);

}

.btn-nfse-novo {
    /* background-color: rgb(24, 23, 29); */
    background-color: rgb(20, 62, 87);
    padding: 10px;
    margin: 5px;
    width: 20%;
    border-radius: 10px;
}

.btn-nfse-novo button {
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #fff;
    background-color: rgb(20, 62, 87);
}


.nf-top {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    background-color: #ececec;
    width: 50%;
    margin: 10px auto;
    height: 60px;
    color: #fff;
    border-radius: 10px;
}

.div-p-top {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    margin: auto;
    font-weight: 600;
    align-items: center;
    width: 50%;
    height: 100%;
    color: #fff;
}

.nf-top button {
    border: none;
    border-radius: 15px;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-right: 15px;
    background-color: rgb(89, 155, 212);
    color: #fff;
    cursor: pointer;
}

.nfse-filtro {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 8%;
    background-color: rgb(46, 99, 129);
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    gap: 5px;
}

.nf-filtro h2 {
    color: #fff;
    margin-left: 10px;
}

.nfse-custom-textfield {
    width: 180px;
    background-color: rgb(20, 62, 87);
    height: 45px;
    border-radius: 10px;
}

.custom-textfield .MuiOutlinedInput-root fieldset {
    border-color: blue;
}

.custom-textfield .MuiOutlinedInput-root:hover fieldset {
    border-color: lightblue;
}

.custom-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: green;
}

.custom-textfield-height .MuiInputBase-root {
    height: 100%;
}

.btn-filtrar-nfe {
    width: 100px;
    height: 35px;
    text-align: center;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(20, 62, 87);
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

.btn-cancelar-nfse {
    background-color: rgb(161, 58, 58);
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-excluir-nfse {
    background-color: rgb(141, 9, 9);
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-concluir-nfse {
    background-color: rgb(97, 8, 97);
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

/* .btn-enviar-nfse {
    background-color: rgb(32, 85, 199);
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
} */

.btn-enviar-nfse {
    /* Estilos gerais do botão */
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 70px;
    height: 30px;
    transition: opacity 0.3s ease;
}

.btn-ativa-nfse {
    cursor: pointer;
    padding: 5px;
}

.btn-repetir-nfse {
    background-color: rgb(9, 116, 9);
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.div-total {
    width: 30%;
    margin-left: 20px;
    margin-right: 10px;
}

.nfse-table {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 77%;
    background-color: #ffff;
    border-radius: 10px;
}

.filtro {
    width: 150px;
    height: 30px;
}

.linha-pendente {
    background-color: #fcf5e1;
    display: flex;
}

.linha-cancelada {
    background-color: #ffc9b3;
    display: flex;
}

.linha-enviada {
    background-color: #b9ecee;
    display: flex;
}

.linha-processando {
    background-color: #768edb;
    display: flex;
}