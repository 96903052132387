
.btn-fechar {
    background-color: #0f1729;
    color: #fff;
    width: 80px;
    height: 40px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.btn-incluir {
    background-color: #1a9292;
    color: #fff;
    width: 80px;
    height: 40px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.dl-container-nfse {
    gap: 15px;
    /* background-color: #1a9292; */
}

.select-item {
    width: 350px;
    height: 30px;
    margin: auto auto auto 10px;
    border: none;
    border-radius: 10px;
    /* background-color: rgb(165, 201, 233); */
    /* color: #fff; */
    font-size: 16px;
    padding: 5px;
}