.container-compromissos{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.container-top-compromisso {
    display: flex;    
    font-size: 16px;
    margin: 5px auto;
    width: 50%;
    height: 60px;
    min-height: 50px;
}

.column-top-compromisso {
    flex: 1;
    text-align: center;
    padding: 10px;
    margin: 5px;
    width: 80%;
    border: none;
    border-radius: 10px;
    background-color: rgb(43, 31, 116);
}

.nf-filtro-comp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #fff;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    gap: 5px;
}

.btn-filtrar-comp {
    width: 100px;
    height: 30px;
    text-align: center;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(89, 155, 212);
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

.div-total-comp{
    width: 30%;
    margin-left: 20px;
    margin-right: 10px;
}

.nf-table-comp {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 77%;
    background-color: #fff;
    border-radius: 10px;
}