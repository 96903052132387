
.emi-container-emitente {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.emi-container-top {
    display: flex;    
    font-size: 16px;
    margin: 5px auto;
    width: 50%;
    height: 60px;
    min-height: 50px;
}

.btn-ativar {
    width: 100px;            
    height: 30px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: #fff;
    background-color: #2d4b9e;
}

.emi-column-top {
    flex: 1;
    text-align: center;
    padding: 10px;
    margin: 5px;
    width: 80%;
    border: none;
    border-radius: 10px;
    background-color: rgb(20, 62, 87);
}

.column-top-btn {
    background-color: rgb(20, 62, 87);
    padding: 10px;
    margin: 5px;
    width: 20%;
    border-radius: 10px;
}

.column-top-btn button {
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #fff;
    background-color: rgb(24, 23, 29);
}

.emitente-meio {
    background-color: #fff;
    display: flex;
    justify-content: center;        
    /* margin: 10px; */
    width: 100%;
    border-radius: 10px;
}

.table-body {
    font-size: 1em;
}

.green-cell {
    background-color: green;
    color: white;
  }
  
 .btn_excluir_emit {
    background-color: rgb(199, 32, 32);
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
} 

.btn_desativa_emit {
    background-color: rgb(133, 29, 37);
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
} 