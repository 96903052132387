.home-container {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
}

.div-meio {
    display: flex;
    flex-direction: column;
    width: 98%;
    height: 100vh;
    border-radius: 15px;
    /* margin: auto; */
    padding: 50px 20px;
}

.div-esquerda {
    background-color: #143e57;
    width: 220px;
    height: 100vh;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
}

.nav-footer {
    margin-top: auto;
    text-align: center;
    color: #fff;
}

.social-icons img {
    margin: 5px;
    cursor: pointer;
}

.social-icons img:hover {
    transform: scale(1.1);
    transition: transform 0.3s;
}


.bkpdiv-esquerda {
    background-color: rgb(20, 62, 87);
    width: 25%;
    height: 90%;
    max-height: 90%;
    /* overflow-y: auto; */
    /* Adiciona a barra de rolagem quando necessário */
    border-radius: 15px;
    margin: auto 10px auto 30px;
    max-width: 200px;
}

.div-meio-superior {
    display: flex;
    background-color: #e1e1e1;
    width: 98%;
    height: 8%;
    max-height: 100px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.div-meio-superior p {
    background-color: #e1e1e1;
    margin: auto 5px auto 10px;
    max-width: 70px;
    min-width: 70px;
}

.ref-select {
    width: 25%;
    height: 30px;
    margin: auto 10px;
    border: none;
    border-radius: 10px;
    background-color: rgb(42, 101, 153);
    color: #fff;
    font-size: 16px;
    padding: 5px;
    max-width: 250px;
}

.p-select {
    width: 7%;
    height: 30px;
    margin: auto 10px;
    border: none;
    border-radius: 10px;
    color: #000;
    font-size: 16px;
    padding: 5px;
}

.btn-select {
    width: 15%;
    height: 30px;
    margin: auto 10px;
    border: none;
    border-radius: 10px;
    background-color: rgb(89, 155, 212);
    color: #fff;
    font-size: 16px;
    padding: 5px;
    cursor: pointer;
    max-width: 100px;
}

.div-xx {
    width: 25%;
    padding: 10px;
    margin: auto 10px;
    display: grid;
    background-color: #ececec;
}

.btn_meio {
    width: 98%;
    border-radius: 15px;
    height: 60px;
    padding: 10px;
    margin: 10px;
    background-color: rgb(89, 155, 212);
    color: #fff;
    font-size: medium;
    border: none;
    cursor: pointer;
}

.div-meio-baixo {
    display: flex;
    justify-content: space-between;
    width: 98%;
    height: 48%;
    /* background-color: #e1e1e1; */
    border-radius: 10px;
}

.div-resumo {
    background-color: #1E90FF;
    color: #fff;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    /* Adicionando propriedades para centralizar */
    display: flex;
    justify-content: center;
    align-items: center;
}

.div-resumo h3 {
    margin: 0; /* Remove a margem padrão do h3 */
    color: #000;
    text-align: center; /* Centraliza o texto dentro do h3 */
}

.div-x {
    background-color: #ececec;
    margin: 5px;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 20px;  
    /* margin: auto;  */
}

.div-meio-meio-home {
    display: flex;
    width: 98%;
    height: 48%;
    border-radius: 10px;
    margin-bottom: 10px;
}

.nfsex {
    background-color: #ececec;
    margin: 5px;
    justify-content: center;
    text-align: center;
    width: 100%;
    /* height: 100%; */
    border-radius: 10px;
    padding: 20px;
}

.img-esquerda {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.nav-esquerda {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}

.bkpimg-esquerda {
    width: 80px;
    height: 50px;
    margin: 10px;
}

.bkpnav-esquerda {
    margin-top: 20px;
}

.nav-baixo {
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    bottom: 10px;
    padding: 15px;
}

.nav-baixo img {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.icon-face {
    margin-top: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.nav-esquerda li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    transition: background 0.3s ease;
}

.nav-esquerda li:hover {
    background-color: #1d5a78;
}

.nav-esquerda li img {
    width: 30px;
    height: 30px;
}

#ul-navbar {
    display: grid;
    gap: 20px;
    text-align: left;
}

#ul-navbar li {
    list-style: none;
    /*tira a bolinha de tras do link*/
    padding: 0 10px;
    /*position: relative;*/
}

#ul-navbar li a {
    text-decoration: none;
    /*tira o traço de baixo do link*/
    /*font-size: 1.1rem;*/
    font-weight: 500;
    /*Intensidade da cor*/
    color: #fff;
    transition: 0.3s ease-in-out;
    /*quando passo o mouse em cima muda devagar*/
}

#ul-navbar li img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 5px;
}

.ref-span {
    display: flex;
    width: 350px;
    height: 30px;
    margin: auto auto auto 80px;
    font-weight: 700;
    font-size: 24px;
}

.btn-excluir {
    background-color: rgb(199, 32, 32);
    width: 60px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
}

.btn-concluir {
    background-color: rgb(32, 199, 96);
    width: 60px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
}

.sel-emitente {
    background-color: rgb(20, 62, 87);
    color: #fff;
    margin: 15px;
    width: 350px;
    font-size: 18px;
    border-radius: 10px;
}
