.container-pagar{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.container-top-pagar {
    display: flex;    
    font-size: 16px;
    margin: 5px auto;
    width: 60%;
    height: 60px;
    min-height: 50px;
}

.column-top-pagar {
    flex: 1;
    text-align: center;
    padding: 10px;
    margin: 5px;
    width: 80%;
    border: none;
    border-radius: 10px;
    background-color: rgb(20, 62, 87);
}

.btn-baixar-pagar {    
    cursor: pointer;
    border-radius: 10px;    
    height: 85%;
    margin: auto;
    font-size: 16px;
    margin-right: 10px;
}

.nf-filtro-pagar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    height: 10%;
    background-color: rgb(46, 99, 129);
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    gap: 5px;
}

.btn-filtrar-pagar {
    width: 100px;
    height: 30px;
    text-align: center;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(20, 62, 87);
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

.div-total-pagar{
    width: 25%;
    margin-left: 10px;
    /* margin-right: 10px; */
    font-size: 14px;
}

.btn-excluir-pagar {
    background-color: rgb(121, 4, 4);
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-ativa-pagar {
    cursor: pointer;
    padding: 5px;
    margin-top: 5px;
 }
 