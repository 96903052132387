.container-despesas{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.despesa-top {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    background-color: rgb(43, 31, 116);
    width: 50%;
    margin: 10px auto;
    height: 60px;
    color: #fff;
    border-radius: 10px;    
}

.div-p-top{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    margin: auto;
    font-weight: 600;
    align-items: center;
    width: 50%;
    height: 100%;
    color: #fff;
}

.despesa-top button {
    border: none;
    border-radius: 15px;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-right: 15px;
    background-color: rgb(89, 155, 212);
    color: #fff;
    cursor: pointer;
}

.despesa-filtro {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 13%;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 5px;
    padding: 20px;
}

.despesa-filtro h3 {
    color: #000;
    margin-left: 10px;
}

.custom-textfield {
    width: 250px;
    background-color: #fff;
}

.custom-textfield .MuiOutlinedInput-root fieldset {
    border-color: blue;
}

.custom-textfield .MuiOutlinedInput-root:hover fieldset {
    border-color: lightblue;
}

.custom-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: green;
}

.custom-textfield-height {
    height: 45px;
    /* ajuste a altura conforme necessário */
}

.custom-textfield-height .MuiInputBase-root {
    height: 100%;
}

.btn-filtrar{
    width: 100px;
    height: 35px;
    text-align: center;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(89, 155, 212);
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

.btn-cancelar {
    background-color: rgb(161, 58, 58);
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-concluir {
    background-color: rgb(32, 199, 96);
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-enviar {
    background-color: rgb(32, 85, 199);
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.div-total{
    width: 30%;
    text-align: right;
    margin-left: 30px;
    font-size: 16px;
}

.despesa-table {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 77%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}