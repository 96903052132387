.d-container {   
    background: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    margin: 0 auto;     
    width: 600px;   
    height: 400px;
    /* background-color: #1a9292; */
}

.btn-fechar {
    background-color: #0f1729;
    color: #fff;
    width: 80px;
    height: 40px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.btn-incluir {
    background-color: #1a9292;
    color: #fff;
    width: 80px;
    height: 40px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.dl-container {
    gap: 15px;
}