.container-faturamento {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.container-top-faturamento {
    display: flex;
    font-size: 16px;
    margin: 5px auto;
    width: 90%;
    height: 60px;
    min-height: 50px;
}

.column-top-faturamento {
    flex: 1;
    text-align: center;
    padding: 10px;
    margin: 5px;
    width: 80%;
    border: none;
    border-radius: 10px;
    background-color: rgb(20, 62, 87);
}

.btn-novo-fat {
    background-color: rgb(20, 62, 87);
    padding: 10px;
    margin: 5px;
    width: 90px;
    border-radius: 10px;
}

.btn-novo-fat button {
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #fff;
    background-color: rgb(20, 62, 87);
}


.nf-filtro-fat {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: rgb(46, 99, 129);
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    gap: 5px;
}

.fat-custom-textfield {
    width: 160px;
    height: 45px;
    color: #fff;
    border-radius: 10px;
    background-color: rgb(20, 62, 87);
}

.custom-textfield .MuiOutlinedInput-root fieldset {
    border-color: blue;
}

.custom-textfield .MuiOutlinedInput-root:hover fieldset {
    border-color: lightblue;
}

.custom-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: green;
}

.custom-textfield-height .MuiInputBase-root {
    height: 100%;
}


.div-total-fat {
    width: 25%;
    margin-left: 10px;
    /* margin-right: 10px; */
    font-size: 14px;
}

.nf-table-fat {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 77%;
    background-color: #fff;
    border-radius: 10px;
}

.btn-filtrar-fat {
    width: 100px;
    height: 30px;
    text-align: center;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(20, 62, 87);
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

.btn-consulta-fat {
    background-color: rgb(8, 15, 97);
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-excluir-fat {
    background-color: rgb(121, 4, 4);
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-gerar-fat {
    cursor: pointer;
    border-radius: 10px;
    height: 85%;
    margin: auto;
    font-size: 16px;
    margin-right: 10px;
}

.btn-gerar-nfse {
    background-color: rgb(6, 123, 93);
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.column-top-btn-fat {
    padding: 10px;
    margin: 5px;
    width: 20%;
    border-radius: 10px;
}

.linha-recebido {
    background-color: #b9ecee;
    font-weight: 500;
    /* Cor para linhas aprovadas */
}

.linha-aberto {
    background-color: #fcf5e1;
    /* Cor para linhas pendentes */
}

.linha-nfse {
    background-color: aquamarine;
    /* cor para nfse ja gerada */
}