.container-impostos {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.imposto-top {
    display: flex;
    font-size: 16px;
    margin: 5px auto;
    width: 50%;
    height: 60px;
    min-height: 50px;
}

.div-p-top {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    margin: auto;
    font-weight: 600;
    align-items: center;
    width: 50%;
    height: 100%;
    color: #fff;
}

.imposto-top button {
    border: none;
    border-radius: 15px;
    width: 100px;
    height: 30px;
    margin: auto;
    background-color: rgb(24, 23, 29);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
}

.imposto-filtro h3 {
    color: #000;
    margin: auto;
    padding: 10px;
}

.btn-filtrar {
    width: 100px;
    height: 35px;
    text-align: center;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(89, 155, 212);
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

.btn-cancelar {
    background-color: rgb(161, 58, 58);
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-concluir {
    background-color: rgb(32, 199, 96);
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-enviar {
    background-color: rgb(32, 85, 199);
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-pdf {
    background-color: rgb(58, 161, 147);
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.div-total {
    width: 30%;
    text-align: right;
    margin-left: 30px;
    font-size: 16px;
}

.imposto-table {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 77%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.imposto-column-top {
    flex: 1;
    text-align: center;
    padding: 10px;
    margin: 5px;
    width: 80%;
    border: none;
    border-radius: 10px;
    /* background-color: #76848c; */
    background-color: rgb(20, 62, 87);
}

.imposto-column-top-btn {
    background-color: rgb(24, 23, 29);
    padding: 10px;
    margin: 5px;
    width: 20%;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.imposto-filtro {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    height: 8%;
    background-color: rgb(46, 99, 129);
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 5px;
    padding: 10px;
}

.imposto-btn-filtrar{
    width: 100px;
    height: 35px;
    text-align: center;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: rgb(89, 155, 212);
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

.imposto-div-total{
    width: 30%;
    text-align: right;
    margin-left: 30px;    
}

.imp-nfse-custom-textfield {
    width: 200px;
    background-color: #fff;
}

.imp-custom {
    width: 180px;
    background-color: rgb(20, 62, 87);
    height: 45px;
    border-radius: 10px;
}
