
.container-credores {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.credor-top{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    /* background-color: rgb(43, 31, 116); */
    width: 50%;
    margin: 5px auto;
    height: 60px;
    color: #fff;
    border-radius: 10px;
}

.credor-container-top {
    display: flex;    
    font-size: 16px;
    margin: 5px auto;
    width: 50%;
    height: 60px;
    min-height: 50px;
}

.credor-column-top {
    flex: 1;
    text-align: center;
    padding: 10px;
    margin: 5px;
    width: 80%;
    border: none;
    border-radius: 10px;
    background-color: rgb(20,62,87);
}

.btn-novo-credor {
    justify-content: center;
    text-align: center;
    background-color: rgb(20,62,87);
    padding: 10px;
    margin: 5px;
    width: 15%;
    border-radius: 10px;
}

.btn-novo-credor button {
    font-size: 20px;
    background-color: rgb(20, 62, 87);
    color: #fff;
    border: none;
    
}

.column-top-btn button {
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #fff;
    background-color: rgb(20, 62, 87);
}


.credor-top h3 {
    color: #fff;
}

.credor-meio{
    background-color: #fff;
    display: flex;
    justify-content: center;        
    margin: 10px auto;
    width: 100%;
    border-radius: 10px;
    min-height: 500px;
    /* padding: 10px; */
}

.table-body {
    font-size: 1em;
}

.btnNovo button {
    border: none;
    border-radius: 10px;
    /* background-color: rgb(43, 31, 116); */
    color: #fff;
    cursor: pointer;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
}

.btn_excluir {
    background-color: rgb(199, 32, 32);
    width: 100px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.div-filtro-credores {
    background-color: rgb(46, 99, 129);
    display: flex;
    gap: 20px;
    width: 80%;
    height: 60px;
    border: none;
    border-radius: 10px;
}

.div-filtro-credores label {
    font-size: 20px;
    color: #fff;
    margin: auto 20px;
    font-weight: 500;
    
}

.filtrar-credor {
    background-color: rgb(20, 62, 87);
    color: #fff;
    margin: 10px;
    width: 150px;
    font-size: 18px;
    border-radius: 10px;
}

.linha-ativo {
    background-color: #fcf5e1;
}

.linha-inativo {
    background-color: #ffc9b3;
}
